<template>
  <tr>
    <td>{{ value.category.name }}<br /><small>Category Input</small></td>
    <td>
      <b-badge variant="warning" v-if="hasPenalty && penalty.penalty > 0" v-b-tooltip.hover title="Liquidation will incur penalty">
        <i class="fa fa-legal"></i> <percent :value="penalty.penalty"></percent>
      </b-badge>
      <b-badge variant="warning" v-if="hasPenalty && penalty.taxRate > 0" v-b-tooltip.hover title="Liquidation will incur taxes">
        <i class="fa fa-balance-scale"></i> <percent :value="penalty.taxRate"></percent>
      </b-badge>
    </td>
    <td class="text-right">
      <dollars v-if="value.category.liquid" :value="value.balanceAvailable"></dollars>
    </td>
    <td class="text-right">
      <dollars v-if="value.category.monthly" :value="value.monthlyAmountAvailable"></dollars>
    </td>
    <td>
      <b-button size="sm" variant="primary" @click="liquidate()" :disabled="!canLiquidate"><i class="cui-arrow-thick-right"></i></b-button>
    </td>
    <td><b-form-input type="text" size="sm" v-model="value.balanceToUse" v-if="value.category.liquid" @blur.native="save()" /></td>
    <td><b-form-input type="text" size="sm" v-model="value.monthlyAmount" v-if="value.category.monthly" @blur.native="save()" /></td>
    <td class="text-right">
      <b-form-checkbox size="sm" v-model="selected" v-if="value.category.liquid || value.category.monthly" />
    </td>
  </tr>
</template>
<script>
import Dollars from '@/components/text/Dollars'
import Percent from '@/components/text/Percent'

export default {
  props: [ 'value', 'age', 'taxRate' ],
  components: {
    Dollars,
    Percent
  },
  name: 'CategoryInputRow',
  data () {
    return {
      selected: this.value.use
    }
  },
  computed: {
    canLiquidate () {
      return (this.value.category.liquid || this.value.category.monthly)
        && (this.value.balanceAvailable > 0 || this.value.monthlyAmountAvailable > 0)
        && (this.value.balanceAvailable > this.value.balanceToUse || this.value.monthlyAmountAvailable > this.value.monthlyAmount)
    },
    hasPenalty () {
      if (this.age >= 59.5) {
        return this.value.category.taxable
      } else {
        return this.value.category.penalty || this.value.category.taxable
      }
    },
    penalty () {
      const penalty = { penalty: 0.00, taxRate: 0.00 }
      const taxRate = parseFloat(this.taxRate)

      if (this.age >= 59.5) {
        if (this.value.category.taxable) {
          if (this.value.category.capitalGains) {
            penalty.taxRate = 15
          } else {
            penalty.taxRate = taxRate
          }
        }
      } else {
        if (this.value.category.penalty) {
          penalty.penalty = 10.0
        }
        if (this.value.category.taxable) {
          if (this.value.category.capitalGains) {
            penalty.taxRate = 15
          } else {
            penalty.taxRate = taxRate
          }
        }
      }

      return penalty
    }
  },
  methods: {
    liquidate () {
      this.value.balanceToUse = this.value.balanceAvailable || 0.0
      this.value.monthlyAmount = this.value.monthlyAmountAvailable || 0.0
      if (!this.selected) {
        this.selected = true
      }
      this.save()
    },
    save () {
      if (this.value.balanceToUse > this.value.balanceAvailable) {
        this.value.balanceToUse = this.value.balanceAvailable || 0.0
      }

      if (this.value.monthlyAmount > this.value.monthlyAmountAvailable) {
        this.value.monthlyAmount = this.value.monthlyAmountAvailable || 0.0
      }

      this.value.balanceToUse = this.value.balanceToUse || 0.0
      this.value.monthlyAmount = this.value.monthlyAmount || 0.0

      if (this.value.balanceAvailable > 0 || this.value.monthlyAmount > 0) {
        if (!this.selected) {
          this.selected = true
        }
      }

      const penalty = this.penalty
      if (penalty.penalty > 0) {
        this.value.penalty = parseFloat(this.value.balanceToUse) * (penalty.penalty / 100)
      }
      if (penalty.taxRate > 0) {
        let taxableAmount = parseFloat(this.value.balanceToUse)
        this.value.taxes = taxableAmount * (penalty.taxRate / 100)
      }

      this.$api.strategies.inputs.update({ id: this.value.id }, this.value)
        .then(() => {
          this.$emit('changed')
        })
        .catch(() => {
          this.$snotify.success('Error Saving Input')
        })
    },
    unlink (input) {
      this.$api.strategies.inputs.delete({ id: input.id })
        .then(() => {
          this.$snotify.success('Input Unlinked Successfully')
          this.changed({ remove: input.id })
        })
        .catch(() => {
          this.$snotify.error('Unlink Input Failed')
        })
    },
    changed (data) {
      this.$emit('changed', data)
    }
  },
  watch: {
    'selected': function () {
      this.value.use = this.selected
      this.$api.strategies.inputs.update({ id: this.value.id }, this.value)
        .then(() => {
          this.$emit('changed')
        })
        .catch(() => {
          this.$snotify.success('Error Saving Input')
        })
    }
  }
}
</script>

<style scoped>
.badge {
  font-size: 85%;
  margin-right: 0.25rem;
}
.badge:last-child {
  margin-right: 0px;
}
.badge .fa {
  margin-right: 0.25rem;
}
</style>
