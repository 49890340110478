<template>
  <b-row>
    <b-col sm="12">
      <b-row>
        <b-col sm="8">
          <b-card>
            <template slot="header">
              <b-row class="clickable" @click="editStrategy()">
                <b-col sm="8">
                  Strategy Info
                </b-col>
                <b-col sm="4" class="text-right">
                  <b-button size="sm" variant="ghost-primary" @click.stop="editStrategy()">
                    <i class="fa fa-pencil"></i> Edit
                  </b-button>
                </b-col>
              </b-row>
            </template>
            <b-card-body class="clickable" @click="editStrategy()">
              <b-row>
                <b-col sm="4">
                  <strong>Strategy:</strong> {{ currentStrategy.name }} <i v-if="currentStrategy.locked" class="fa fa-lock"></i>
                </b-col>
                <b-col sm="4">
                  <strong>Start Date:</strong> {{ startDate }}
                </b-col>
                <b-col sm="4">
                  <strong>Tax Rates:</strong> <small>Income:</small> <percent :value="currentStrategy.taxRate"></percent> <small>Capital Gains:</small> <percent :value="currentStrategy.capitalGainsTaxRate"></percent>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col sm="4">
          <b-card>
            <template slot="header">
              Input Summary
            </template>
            <b-card-body>
              <p><strong>Monthly Amount: </strong><dollars :value="totalMonthly"></dollars></p>
              <hr />
              <p>
                <strong>Balance to Use: </strong><dollars :value="totalBalance"></dollars><br />
                <strong>Penalties: </strong><dollars :value="totalPenalties"></dollars><br />
                <strong>Reserved for Taxes: </strong><dollars :value="totalReservedTaxes"></dollars><br />
                <strong>Remaining Available Balance: </strong><dollars :value="totalLiquid"></dollars>
              </p>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12">
          <b-card v-if="inputs.length > 0 || missingInputs.length > 0">
            <template slot="header">
              <b-row>
                <b-col sm="12">
                  Strategy Inputs
                </b-col>
              </b-row>
            </template>
            <b-card-body>
              <table v-if="inputs.length > 0" class="table b-table table-sm">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th></th>
                    <th class="text-right">Balance Available</th>
                    <th class="text-right">Monthly Available</th>
                    <th></th>
                    <th>Balance to Use</th>
                    <th>Monthly Amount</th>
                    <th class="text-right">Use?</th>
                    <th v-if="!legacyInputs" class="text-right">Link Status</th>
                  </tr>
                </thead>
                <tbody>
                  <input-row :value="input" :age="age" :taxRate="currentStrategy.taxRate" :capitalGainsTaxRate="currentStrategy.capitalGainsTaxRate" :locked="currentStrategy.locked" @changed="inputChanged" v-for="input in nonCategoryInputs" :key="input.id"></input-row>
                  <category-input-row :value="input" :age="age" :taxRate="currentStrategy.taxRate" :capitalGainsTaxRate="currentStrategy.capitalGainsTaxRate" @changed="inputChanged" v-for="input in categoryInputs" :key="input.id"></category-input-row>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="9" class="text-right">
                      <b-button size="sm" v-if="syncNeeded" variant="ghost-warning" @click.stop="updateAll()" v-b-tooltip.hover title="One or more of the source inputs changed.  Sync changes?">
                        <i class="fa fa-refresh"></i> Refresh All
                      </b-button>
                    </td>
                  </tr>
                </tfoot>
              </table>
              <b-alert :show="inputs.length === 0" variant="warning"><strong>No Strategy Inputs:</strong> Add one or more available inputs below</b-alert>
              <p v-if="this.inputs.length > 0"><strong>Note: </strong> These inputs will not automatically update if the values are changed on the client screen.  Use the link status options to synchronize the changes.</p>
            </b-card-body>
          </b-card>
          <b-alert :show="inputs.length === 0 && missingInputs.length === 0" variant="warning"><strong>No Inputs Available:</strong> Please complete the client info section.</b-alert>
          <b-card v-if="!legacyInputs && (currentStrategy.id > 0 && missingInputs.length > 0)">
            <template slot="header">
              <b-row>
                <b-col sm="6">
                  Available Inputs
                </b-col>
                <b-col sm="6" class="text-right">
                  <b-btn v-if="!legacyInputs" size="sm" variant="primary" @click="addAllInputs()"><i class="fa fa-plus"></i> Add All Available</b-btn>
                </b-col>
              </b-row>
            </template>
            <b-card-body>
              <table class="table b-table table-sm">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Category</th>
                    <th class="text-right">Balance Available</th>
                    <th class="text-right">Monthly Available</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="input in missingInputs" :key="input.id" class="">
                    <td>{{ input.name }}</td>
                    <td>{{ input.inputCategory ? input.inputCategory.name : '' }}</td>
                    <td class="text-right">
                      <dollars v-if="input.inputCategory && input.inputCategory.liquid" :value="input.balanceAvailable"></dollars>
                    </td>
                    <td class="text-right">
                      <dollars v-if="input.inputCategory && input.inputCategory.monthly" :value="input.monthlyAmountAvailable"></dollars>
                    </td>
                    <td class="text-right">
                      <b-button v-if="!legacyInputs" size="sm" variant="ghost-success" @click.stop="addInput(input)" v-b-tooltip.hover title="Add to Strategy">
                        <i class="fa fa-plus"></i>
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p><strong>Note:</strong> After adding an input, you'll be able to adjust the amount of that input to use in the strategy.</p>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <strategy-modal @save="saveStrategy"></strategy-modal>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from 'vuex'
import StrategyModal from '@/components/modals/StrategyModal'
import Dollars from '@/components/text/Dollars'
import Percent from '@/components/text/Percent'
import CategoryInputRow from './CategoryInput'
import InputRow from './Input'
import moment from 'moment'

export default {
  name: 'Inputs',
  components: {
    CategoryInputRow,
    Dollars,
    InputRow,
    Percent,
    StrategyModal
  },
  data: () => {
    return {
      dateConfig: {
        altFormat: 'm/d/Y',
        altInput: true
      },
      strategy: {
        name: null
      },
      inputs: [],
      totalBalance: 0.0,
      totalMonthly: 0.0,
      totalPenalties: 0.0,
      totalReservedTaxes: 0.0,
      totalLiquid: 0.0
    }
  },
  computed: {
    ...mapGetters({
      notifications: 'global/notifications',
      currentClient: 'global/currentClient',
      currentStrategy: 'global/currentStrategy',
      assetTypes: 'global/assetTypes'
    }),
    legacyInputs() {
      const categoryInputs = this.inputs.filter(o => o.type === null)
      return categoryInputs.length > 0
    },
    startDate () {
      return moment(this.currentStrategy.startAt).format('MM/DD/YYYY')
    },
    syncNeeded () {
      return false
    },
    categoryInputs () {
      return this.inputs.filter(o => o.type === null).map((categoryInput) => {
        const categoryAvailable = [].concat(
          this.currentClient.inputs.assets,
          this.currentClient.inputs.creditCards,
          this.currentClient.inputs.loans,
          this.currentClient.inputs.policies,
          this.currentClient.inputs.mortgages,
          this.currentClient.inputs.vehicles
        )
        .filter(o => parseInt(o.categoryId) === parseInt(categoryInput.categoryId))
        .reduce((available, input) => {
          let balanceAvailable = input.balanceAvailable || 0.0
          let monthlyAmountAvailable = input.monthlyAmountAvailable || 0.0

          available.balance += parseFloat(balanceAvailable)
          available.monthly += parseFloat(monthlyAmountAvailable)
          return available
        }, { balance: 0.0, monthly: 0.0 })
        categoryInput.balanceAvailable = categoryAvailable.balance
        categoryInput.monthlyAmountAvailable = categoryAvailable.monthly
        return categoryInput
      }).filter(o => o.balanceAvailable > 0 || o.monthlyAmountAvailable > 0 || o.balanceToUse > 0 || o.monthlyAmount > 0)
    },
    age () {
      const now = moment()
      return now.diff(moment(this.currentClient.primaryContact.birthday), 'years', true)
    },
    nonCategoryInputs () {
      return this.inputs.filter(o => o.type !== null)
    },
    missingInputs () {
      let missingInputs = []

      // Missing Assets
      const assets = this.currentClient.inputs.assets.filter((input) => {
        return this.inputs.findIndex((o) => o.assetId === input.assetId) < 0
      })
      missingInputs = missingInputs.concat(assets)

      // Missing Credit Cards
      const creditCards = this.currentClient.inputs.creditCards.filter((input) => {
        return this.inputs.findIndex((o) => o.creditCardId === input.creditCardId) < 0
      })
      missingInputs = missingInputs.concat(creditCards)

      // Missing Loans (no type)
      const loans = this.currentClient.inputs.loans.filter((input) => {
        return this.inputs.findIndex((o) => o.loanId === input.loanId) < 0
      })
      missingInputs = missingInputs.concat(loans)

      // Missing Mortgages
      const mortgages = this.currentClient.inputs.mortgages.filter((input) => {
        return this.inputs.findIndex((o) => o.mortgageId === input.mortgageId) < 0
      })
      missingInputs = missingInputs.concat(mortgages)

      // Missing Policies
      const policies = this.currentClient.inputs.policies.filter((input) => {
        return this.inputs.findIndex((o) => o.policyId === input.policyId) < 0
      })
      missingInputs = missingInputs.concat(policies)

      // Missing Vehicles (no type)
      const vehicles = this.currentClient.inputs.vehicles.filter((input) => {
        return this.inputs.findIndex((o) => o.vehicleId === input.vehicleId) < 0
      })
      missingInputs = missingInputs.concat(vehicles)

      return missingInputs.filter(o => o.balanceAvailable > 0 || o.monthlyAmountAvailable > 0).sort((a, b) => {
        if (a.name > b.name) {
          return 1
        } else if (a.name < b.name) {
          return -1
        } else {
          return 0
        }
      })
    }
  },
  methods: {
    editStrategy () {
      this.$bus.$emit('modal:editStrategy', this.currentStrategy)
    },
    async saveStrategy (strategy) {
      try {
        // Auto update the seasoning if the date changes
        for (const policy of this.currentStrategy.policies) {
          if (policy.startedAt) {
            const startDate = moment(this.currentStrategy.startAt)
            const policyStartDate = moment(policy.startedAt)

            let seasoning = Math.ceil(startDate.diff(policyStartDate, 'years', true))
            if (seasoning < 1) seasoning = null

            if (policy.seasoned != seasoning) {
              policy.seasoned = seasoning
              await this.$api.policies.update({ id: policy.id }, policy)
            }
          }
        }
        const { data } = await this.$api.strategies.update({ id: strategy.id }, strategy)
        this.$store.dispatch('global/getStrategy', {
          id: this.$route.params.strategyId
        })
        this.$snotify.success('Strategy Saved')
      } catch (err) {
        this.$snotify.error('Error Saving Strategy')
      }
    },
    addInput (input) {
      input.strategyId = this.$route.params.strategyId
      this.$api.strategies.inputs.create(input)
        .then((response) => {
          this.inputs.push(response.data)
          this.$snotify.success('Input Added Successfully')
          this.$store.dispatch('global/getStrategy', {
            id: this.$route.params.strategyId
          })
        })
        .catch(() => {
          this.$snotify.error('Add Input Failed')
        })
    },
    inputChanged (data) {
      if (data) {
        if (data.remove) {
          let index = this.inputs.findIndex((o) => o.id === data.remove)
          if (index >= 0) this.inputs.splice(index, 1)
        } else {
          this.$store.dispatch('global/getStrategy', {
            id: this.$route.params.strategyId
          })
        }
      }
      this.updateTotals()
    },
    addAllInputs () {
      let updates = []
      this.missingInputs.forEach((input) => {
        input.strategyId = this.$route.params.strategyId
        updates.push(this.$api.strategies.inputs.create(input).then((response) => this.inputs.push(response.data)))
      })
      Promise.all(updates).then(() => {
        this.$snotify.success('Inputs Added Successfully')
        this.$store.dispatch('global/getStrategy', {
          id: this.$route.params.strategyId
        })
      }).catch(() => {
        this.$snotify.error('Add Inputs Failed')
      })
    },
    updateTotals () {
      this.totalMonthly = 0.0
      this.totalBalance = 0.0
      this.totalPenalties = 0.0
      this.totalReservedTaxes = 0.0
      this.totalLiquid = 0.0
      this.inputs.forEach((input) => {
        const balanceToUse = input.balanceToUse || 0.0
        const monthlyAmount = input.monthlyAmount || 0.0
        const penalty = input.penalty || 0.0
        const taxes = input.taxes || 0.0

        if (input.use) {
          this.totalMonthly += parseFloat(monthlyAmount)
          this.totalBalance += parseFloat(balanceToUse)
          this.totalPenalties += parseFloat(penalty)
          this.totalReservedTaxes += parseFloat(taxes)
        }
      })
      this.totalLiquid = this.totalBalance - this.totalPenalties - this.totalReservedTaxes
    },
    mapInputs (inputs) {
      inputs = inputs.map((o) => {
        o.type === null ? o.name = 'Category Input' : null
        return o
      })
      this.inputs = inputs.sort((a, b) => {
        if (a.name > b.name) {
          return 1
        } else if (a.name < b.name) {
          return -1
        } else {
          if (a.category.name > b.category.name) {
            return 1
          } else if (a.category.name < b.category.name) {
            return -1
          } else {
            return 0
          }
        }
      })
      this.updateTotals()
    }
  },
  created () {
    this.$store.dispatch('global/getStrategy', {
      id: this.$route.params.strategyId
    })
    this.$store.dispatch('global/getClient', {
      id: this.$route.params.id
    })
    if (this.currentStrategy.inputs) {
      this.mapInputs(this.currentStrategy.inputs)
    }
  },
  watch: {
    'currentStrategy.inputs': {
      handler (inputs) {
        this.mapInputs(inputs)
      }
    }
  }
}
</script>

<style lang="scss">
  .clickable {
    cursor: pointer;
  }
  .form-control[readonly] {
    background: inherit;
  }
  div.card-body {
    padding: 0.75em 1.25em 0.75em 1.25em;
  }
  table.table-sm td.numeric, table.table-sm th.numeric {
    text-align: right;
  }
  .table-sm td.delete {
    width: 28px;
  }
  .table-sm td {
    vertical-align: middle;
  }
  .table-sm td.numeric, .table-sm th.numeric {
    text-align: right;
  }
</style>
