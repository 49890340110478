<template>
  <tr>
    <td>{{ value.name }}<br /><small>{{ value.category.name }}</small></td>
    <td>
      <b-badge variant="warning" v-for="tax in taxes" v-bind:key="tax.rateId" v-b-tooltip.hover :title="`Liquidation will incur ${tax.name}`">
        <i class="fa" :class="tax.isPenalty ? 'fa-legal': 'fa-balance-scale'"></i> <percent :value="tax.percentage"></percent>
      </b-badge>
    </td>
    <td class="text-right">
      <dollars v-if="value.category.liquid" :value="value.balanceAvailable"></dollars>
    </td>
    <td class="text-right">
      <dollars v-if="value.category.monthly" :value="value.monthlyAmountAvailable"></dollars>
    </td>
    <td>
      <b-button size="sm" variant="primary" @click="liquidate()" :disabled="!canLiquidate" v-if="!locked"><i class="cui-arrow-thick-right"></i></b-button>
    </td>
    <td><b-form-input type="text" :disabled="locked || value.balanceAvailable === 0.0" size="sm" v-model="value.balanceToUse" v-if="value.category.liquid" @blur.native="save()" /></td>
    <td><b-form-input type="text" :disabled="locked || value.monthlyAmountAvailable === 0.0" size="sm" v-model="value.monthlyAmount" v-if="value.category.monthly" @blur.native="save()" /></td>
    <td class="text-right">
      <b-form-checkbox size="sm" :disabled="locked || (value.balanceToUse === 0.0 && value.monthlyAmount === 0.0)" v-model="selected" v-if="value.category.liquid || value.category.monthly" />
    </td>
    <td class="text-right">
      <b-button v-if="shouldRemove(value)" size="md" variant="ghost-danger" :disabled="locked" @click.stop="removeInput(value)" v-b-tooltip.hover title="The source input was removed.  Delete input from strategy?">
        <i class="fa fa-remove"></i>
      </b-button>
      <b-button v-if="shouldUpdate(value)" size="md" variant="ghost-warning" :disabled="locked" @click.stop="updateInput(value)" v-b-tooltip.hover title="The source input has changed.  Sync changes?">
        <i class="fa fa-refresh"></i>
      </b-button>
      <b-button v-if="!shouldUpdate(value) && !shouldRemove(value)" size="md" :disabled="locked" variant="ghost-success" readonly v-b-tooltip.hover title="No changes to the source input">
        <i class="fa fa-check"></i>
      </b-button>
      <b-button size="md" @click.stop="unlink(value)" variant="ghost-warning" :disabled="locked" v-b-tooltip.hover title="Unlink the input">
        <i class="fa fa-unlink"></i>
      </b-button>
    </td>
  </tr>
</template>
<script>
import Dollars from '@/components/text/Dollars'
import Percent from '@/components/text/Percent'
import { mapGetters } from 'vuex'

export default {
  props: [ 'value', 'age', 'taxRate', 'capitalGainsTaxRate', 'locked' ],
  components: {
    Dollars,
    Percent
  },
  name: 'InputRow',
  data () {
    return {
      selected: this.value.use
    }
  },
  computed: {
    ...mapGetters({
      currentClient: 'global/currentClient',
    }),
    canLiquidate () {
      return (this.value.category.liquid || this.value.category.monthly)
        && (this.value.balanceAvailable > 0 || this.value.monthlyAmountAvailable > 0)
        && (this.value.balanceAvailable > this.value.balanceToUse || this.value.monthlyAmountAvailable > this.value.monthlyAmount)
    },
    taxes () {
      if (!this.value.asset || !this.currentClient || !this.value.asset.type) return []
      const taxRate = parseFloat(this.taxRate)
      const capitalGainsTaxRate = parseFloat(this.capitalGainsTaxRate)
      const countryId = this.currentClient.organization.countryId
      const asset = this.value.asset
      const assetType = asset.type

      const amount = this.value.balanceToUse > 0 ? this.value.balanceToUse : this.value.balanceAvailable
      const provinceId = this.currentClient.primaryContact.address.provinceId || null

      const taxImplications = assetType.taxImplications.filter((implication) => {
        if (implication.countryId != countryId) return false
        const provinceIds = implication.rates.filter(o => o.provinceId != null).map(o => o.provinceId)
        const provinceIdFilter = provinceIds.includes(provinceId) ? provinceId : null
        const rates = implication.rates.filter((rate) => {
          return (rate.shortTerm == asset.shortTerm) &&
            (rate.provinceId == provinceIdFilter) &&
            ((rate.startAge == null || rate.startAge <= this.age) && (rate.endAge == null || this.age <= rate.endAge)) &&
            ((rate.minAmount == null || parseFloat(rate.minAmount) <= amount) && (rate.maxAmount == null || amount <= parseFloat(rate.maxAmount)))
        })
        implication.rate = rates[0]
        if (rates.length == 1) return true
        return false
      })
      return taxImplications.map((implication) => {
        const rate = implication.rate
        const percentage = rate.useTaxRate ? taxRate : (rate.useCapitalGainsTaxRate ? capitalGainsTaxRate : rate.customTaxRate)
        return {
          rateId: rate.id,
          name: implication.name,
          isPenalty: implication.isPenalty,
          profitOnly: implication.profitOnly,
          percentToTax: implication.percentToTax,
          percentage
        }
      })
    },
  },
  methods: {
    liquidate () {
      this.value.balanceToUse = this.value.balanceAvailable || 0.0
      this.value.monthlyAmount = this.value.monthlyAmountAvailable || 0.0
      if (!this.selected) {
        this.selected = true
      }
      this.save()
    },
    calculateTaxes () {
      const taxes = this.taxes
      const taxAmounts = {
        taxes: 0.0,
        penalty: 0.0
      }

      if (taxes.length > 0) {
        let taxableAmount = parseFloat(this.value.balanceToUse)
        let basis = 0.0
        if (this.value.asset && this.value.asset.basis > 0) {
          basis = this.value.asset.basis
        }
        const profit = this.value.balanceAvailable - basis

        taxes.forEach((tax) => {
          if (tax.profitOnly) {
            if (taxableAmount > profit) {
              taxableAmount = profit
            }
          }
          if (taxableAmount < 0) {
            taxableAmount = 0
          }
          taxableAmount *= (tax.percentToTax / 100)
          if (tax.isPenalty) {
            taxAmounts.penalty = taxableAmount * (tax.percentage / 100)
          } else {
            taxAmounts.taxes = taxableAmount * (tax.percentage / 100)
          }
        })
      }
      return taxAmounts
    },
    save () {
      if (this.value.balanceToUse > this.value.balanceAvailable) {
        this.value.balanceToUse = this.value.balanceAvailable || 0.0
      }

      if (this.value.monthlyAmount > this.value.monthlyAmountAvailable) {
        this.value.monthlyAmount = this.value.monthlyAmountAvailable || 0.0
      }

      this.value.balanceToUse = this.value.balanceToUse || 0.0
      this.value.monthlyAmount = this.value.monthlyAmount || 0.0

      if (this.value.balanceAvailable > 0 || this.value.monthlyAmount > 0) {
        if (!this.selected) {
          this.selected = true
        }
      }

      const taxAmounts = this.calculateTaxes()
      this.value.taxes = taxAmounts.taxes
      this.value.penalty = taxAmounts.penalty

      this.$api.strategies.inputs.update({ id: this.value.id }, this.value)
        .then(({ data }) => {
          this.$emit('changed', data)
        })
        .catch(() => {
          this.$snotify.success('Error Saving Input')
        })
    },
    unlink (input) {
      this.$api.strategies.inputs.delete({ id: input.id })
        .then(() => {
          this.$snotify.success('Input Unlinked Successfully')
          this.changed({ remove: input.id })
        })
        .catch(() => {
          this.$snotify.error('Unlink Input Failed')
        })
    },
    sourceInput (input) {
      let sourceInput = null
      switch (input.type) {
        case 'Asset':
          if (input.asset) sourceInput = input.asset
          break
        case 'Credit Card':
          if (input.creditCard) sourceInput = input.creditCard
          break
        case 'Loan':
          if (input.loan) sourceInput = input.loan
          break
        case 'Mortgage':
          if (input.mortgage) sourceInput = input.mortgage
          break
        case 'Policy':
          if (input.policy) sourceInput = input.policy
          break
        case 'Vehicle':
          if (input.vehicle) sourceInput = input.vehicle
          break
      }
      return sourceInput
    },
    shouldRemove (input) {
      if (!this.sourceInput(input)) return true
      return false
    },
    shouldUpdate (input) {
      const sourceInput = this.sourceInput(input)
      const taxAmounts = this.calculateTaxes()

      if (sourceInput) {
        if (input.name != sourceInput.name) return true
        if (parseFloat(sourceInput.balanceAvailable) != input.balanceAvailable) return true
        if (parseFloat(sourceInput.monthlyAmountAvailable) != input.monthlyAmountAvailable) return true
        if (input.penalty != parseFloat(taxAmounts.penalty)) return true
        if (input.taxes != parseFloat(taxAmounts.taxes)) return true
        if (sourceInput.type && input.categoryId != sourceInput.type.inputCategoryId) return true
      }
      return false
    },
    updateInput (input) {
      let sourceInput = this.sourceInput(input)

      if (sourceInput) {
        if (sourceInput.shortTerm) {
          input.shortTerm = sourceInput.shortTerm
        }
        input.name = sourceInput.name
        input.balanceAvailable = sourceInput.balanceAvailable
        input.monthlyAmountAvailable = sourceInput.monthlyAmountAvailable
        if (sourceInput.type) {
          input.categoryId = sourceInput.type.inputCategoryId
        }
        this.save()
      }
    },
    removeInput (input) {
      this.$api.strategies.inputs.delete({ id: input.id })
        .then(() => {
          this.$snotify.success('Input Deleted Successfully')
          this.changed({ remove: input.id })
        })
        .catch(() => {
          this.$snotify.error('Delete input failed')
        })
    },
    changed (data) {
      this.$emit('changed', data)
    }
  },
  watch: {
    'selected': function () {
      this.value.use = this.selected
      this.$api.strategies.inputs.update({ id: this.value.id }, this.value)
        .then(() => {
          this.$emit('changed')
        })
        .catch(() => {
          this.$snotify.success('Error Saving Input')
        })
    }
  }
}
</script>

<style scoped>
.badge {
  font-size: 85%;
  margin-right: 0.25rem;
}
.badge:last-child {
  margin-right: 0px;
}
.badge .fa {
  margin-right: 0.25rem;
}
</style>
